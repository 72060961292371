import axios from "axios";
import isAuthenticated, { getAccessToken } from "../utils/auth";

const createInstance = () => {
  let instance = axios.create({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  instance.defaults.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: isAuthenticated() ? `Token ${getAccessToken()}` : "",
  };

  return instance;
};

const api = createInstance();

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/logout?wrong-token=true";
    } else {
      return Promise.reject(error);
    }
  }
);

export const setToken = (token) => {
  api.defaults.headers = {
    ...api.defaults.headers,
    Authorization: `Token ${token}`,
  };
};

export default api;
