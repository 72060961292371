export const setUser = (user) =>
  localStorage.setItem("OOSINC-user-data", JSON.stringify(user));
export const getUser = () => {
  const data = localStorage.getItem("OOSINC-user-data");
  if (data) return JSON.parse(data);
  return null;
};
export const getAccessToken = () => getUser()?.token?.key;
export const removeUser = () => localStorage.removeItem("OOSINC-user-data");

export const isAuthenticated = () => !!getUser()?.token?.key;
export default isAuthenticated;
