// routing base_url
const baseUrl = process.env.BASE_URL || "";

const routeNames = {
  login: `${baseUrl}/login`,
  logout: `${baseUrl}/logout`,

  homepage: `${baseUrl}/`,
  sessions: `${baseUrl}/`,
  sessionsDetail: {
    pattern: `${baseUrl}/sessions/:id`,
    generate: (id) => `${baseUrl}/sessions/${id}`,
  },

  forbidden: `${baseUrl}/forbidden`,
};

export default routeNames;
