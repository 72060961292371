import {
  Apartment,
  ApartmentOutlined,
  ArrowDropDown,
  GridViewOutlined,
  Logout,
  OpenInNewOutlined,
  PersonOutlined,
  Settings,
} from "@mui/icons-material";
import {
  Avatar,
  Divider,
  IconButton,
  Link,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

const UserDropDown = (props) => {
  const { user } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!user)
    return (
      <>
        {/* <Link component={RouterLink} color="" to="/login">
          <Avatar sx={{ backgroundColor: "text.primary" }}>
            <PersonOutlined />
          </Avatar>
        </Link> */}
      </>
    );

  return (
    <>
      <IconButton onClick={handleClick} title={"Profile"}>
        <Avatar sx={{ backgroundColor: "text.primary" }}>
          {user.user.name ? user.user.name.slice(0, 1) : <PersonOutlined />}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={menuOpen}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "hidden",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            width: "200px",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 22.5,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={{ py: 2 }}>
          {/* <ListItemIcon>
            <Logout fontSize="small" color="error" />
          </ListItemIcon> */}
          <Typography>{user.user.name}</Typography>
        </MenuItem>
        <Divider sx={{ my: "0px !important" }} />
        <MenuItem component={RouterLink} to={"/logout"} sx={{ py: 2 }}>
          <ListItemIcon>
            <Logout fontSize="small" color="error" />
          </ListItemIcon>
          <Typography color="error">Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDropDown;
