import { lazy } from "react";
import routeNames from "./routeNames";

const routes = [
  {
    path: routeNames.login,
    Main: lazy(() => import("../containers/Login")),
    title: "Login Page",
    authRequired: false,
    notAuthenticated: true,
  },
  {
    path: routeNames.logout,
    Main: lazy(() => import("../containers/Logout")),
    title: "Logout Page",
    authRequired: true,
  },

  {
    path: routeNames.homepage,
    Main: lazy(() => import("../containers/sessions/List")),
    title: "Sessions",
    authRequired: true,
  },
  {
    path: routeNames.sessionsDetail.pattern,
    Main: lazy(() => import("../containers/sessions/Detail")),
    title: "Session Detail",
    authRequired: true,
  },

  // {
  //   path: routeNames.forbidden,
  //   Main: lazy(() => import("../containers/Forbidden")),
  //   title: "Forbidden",
  //   authRequired: true,
  // },

  {
    path: "*",
    redirect: routeNames.homepage,
    title: "404 Page",
    authRequired: false,
  },
];

export default routes;
