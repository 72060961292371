import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import { SnackbarProvider } from "notistack";
import theme from "./styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

const target = document.querySelector("#root");
const root = createRoot(target);
root.render(
  <ThemeProvider theme={theme(false)}>
    <CssBaseline />
    <BrowserRouter>
      <SnackbarProvider autoHideDuration={3000}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </ThemeProvider>
);
